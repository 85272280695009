<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!loadingContact">
              <ValidationObserver v-if="contact" v-slot="{ handleSubmit, invalid, touched }" ref="updateForm">
                <form class="authentication-form" @submit.prevent="handleSubmit(updateContact)">
                  <div class="row">
                    <div class="col-md-4">
                      <TextInput v-model="contact.first_name" name="first_name" label="First Name" />
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="contact.last_name" name="last_name" label="Last Name" />
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="contact.email" name="email" rules="email" label="Email" />
                    </div>
                    <div class="col-md-4">
                      <PhoneInput v-model="contact.phone" name="phone" rules="phone" label="Phone number"
                        :only-countries="[]" :disabled-fetching-country="true" />
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="contact.business_name" name="business_name" label="Business Name" />
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="contact.website_url" name="website_url" label="Website URL" />
                    </div>
                    <div class="col-md-4">
                      <BirthdayInput v-model="contact.birthday" />
                    </div>
                    <div class="col-md-4">
                      <BirthdayInput v-model="contact.anniversary" label="Anniversary" />
                    </div>
                  </div>
                  <CustomFields v-model="contact.custom_fields" />
                  <div class="row">
                    <div class="col-md-6">
                      <TextInput v-model="contact.total_amount" name="total_amount"
                        :label="`Total Lifetime Spend (${$store.getters['app/currency']})`" :readonly="true" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Keywords</label>
                        <multiselect v-model="contact.keywords" :options="keywords" :multiple="true" track-by="id"
                          label="name" placeholder="Select keywords"></multiselect>
                      </div>
                    </div>
                    <!-- <div class="col-md-6">
                      <div class="form-group">
                        <label>Is Verified <i v-b-tooltip.hover
                            title="Verified Contact has explicit consent to receive sms messages. Non-Verified contact requires double optin verification."
                            class="uil uil-question-circle cursor-pointer"></i></label>
                        <div class="mt-2">
                          <span class="pr-2" style="font-weight:600;">No</span>
                          <b-form-checkbox v-model="contact.is_verified" name="check-button" switch inline>
                            Yes
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-md-12 mt-4 mb-4 d-flex">
                      <div class="mr-5">
                        <span class="pr-2" style="font-weight:600;">SMS - Opt Out</span>
                        <b-form-checkbox v-if="contact.twilio_opt_out" v-model="contact.toggle_opt_out" v-b-tooltip.hover
                          :disabled="true" title="We can't Opt In this contact as customer unsubscribed on his/her side."
                          name="check-button" switch inline>
                          Opt In
                        </b-form-checkbox>
                        <b-form-checkbox v-else v-model="contact.toggle_opt_out" name="check-button" switch inline>
                          Opt In
                        </b-form-checkbox>
                      </div>
                      <div v-if="contact.email">
                        <span class="pr-2" style="font-weight:600;">Email - Opt Out</span>
                        <b-form-checkbox v-model="contact.toggle_email_opt_out" name="check-button" switch inline>
                          Opt In
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <h5>
                        <i class="uil uil-bolt"></i> Automations in process
                      </h5>
                      <div class="list-keywords">
                        <span class="btn btn-outline-dark btn-xs mb-2 mr-1" @click="handleAddAutomation">
                          <i class="uil uil-plus"></i> Add Automation
                        </span>
                        <span v-for="automation in contact.automations" :key="`keyword-${automation.id}`"
                          v-b-tooltip.hover :title="getAutomationTooltip(automation)"
                          class="mb-2 keyword-item btn btn-primary btn-xs ml-2">
                          {{ automation.name }} <template v-if="automation.process_count > 1">( {{
                            automation.process_count
                          }} )</template><span @click="onStopAutomation(automation)"><i
                              class="uil uil-times"></i></span>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mt-3">
                      <div class="row">
                        <div class="col-md-6">
                          <h5>Information</h5>
                          <div class="mb-1">
                            <span class="d-inline-block" style="width: 200px"><b>Ip:</b></span><span>{{ contact.ip }}</span>
                          </div>
                          <div class="mb-1">
                            <span class="d-inline-block" style="width: 200px"><b>Referral:</b></span><span>{{
                              contact.page
                            }}</span>
                          </div>
                          <div class="mb-1">
                            <span class="d-inline-block" style="width: 200px"><b>Opt-In Type:</b></span><span>{{
                              contact.opt_type | couponType
                            }}</span>
                          </div>
                          <div class="mb-1">
                            <span class="d-inline-block" style="width: 200px"><b>Opt-In Keyword:</b></span><span>{{
                              contact.opt_keyword
                            }}</span>
                          </div>
                          <div class="mb-1">
                            <span class="d-inline-block" style="width: 200px"><b>Opt-In Date:</b></span><span>{{
                              contact.opt_in | datetime('MMM DD, hh:mm A')
                            }}</span>
                          </div>
                          <div v-if="contact.opt_out" class="mb-1">
                            <span class="d-inline-block" style="width: 200px"><b>Opt-Out Date:</b></span><span>{{
                              contact.opt_out | datetime('MMM DD, hh:mm A')
                            }}</span>
                          </div>
                          <div v-if="contact.email_opt_out" class="mb-1">
                            <span class="d-inline-block" style="width: 200px"><b>Email Opt-Out Date:</b></span><span>{{
                              contact.email_opt_out | datetime('MMM DD, hh:mm A')
                            }}</span>
                          </div>
                          <div v-if="contact.email_opt_out && contact.email_unsubscribe_reason" class="mb-1">
                            <span class="d-inline-block" style="width: 200px"><b>Email Opt-Out Reason:</b></span><span>{{
                              contact.email_unsubscribe_reason
                            }}</span>
                          </div>
                          <div class="mb-1">
                            <span class="d-inline-block" style="width: 200px"><b>Location:</b></span><span>{{
                              contact.full_address
                            }}</span>
                          </div>
                          <div class="mb-1">
                            <span class="d-inline-block" style="width: 200px"><b>Disclaimer:</b></span><span>{{
                              contact.disclaimer
                            }}</span>
                          </div>
                          <div v-if="contact.goto_join_url" class="mb-1">
                            <span class="d-inline-block" style="width: 200px"><b>GotoWebinar Join URL:</b></span>
                            <a target="_blank" :href="contact.goto_join_url.url">{{ contact.goto_join_url.url }}</a>
                          </div>
                        </div>
                        <div v-if="showPointLinks" class="col-md-6">
                          <h5>Reward Pages</h5>
                          <div v-for="(item, index) in contact.rewards" :key="index" class="mb-1">
                            <span class="d-inline-block" style="width: 200px"><b>{{ item.coupon_name }}:</b></span><span v-linkified>{{ item.points_link }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-form-group class="mt-4">
                    <div class="d-flex justify-content-between">
                      <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit"
                        class="mr-2">
                        <b-spinner v-if="loadingUpdate" small />
                        <span v-else><i class="uil uil-check"></i> Update</span>
                      </b-button>
                      <router-link class="btn btn-warning " :to="{ name: 'business.contacts.index' }">
                        <span><i class="uil uil-arrow-left"></i> Back</span>
                      </router-link>
                    </div>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <div v-if="contact" class="col-12">
        <div v-if="useIntegration" class="card">
          <div class="card-body">
            <h5 class="card-title mt-0 mb-0 header-title">Samcart</h5>
            <div class="table-responsive mt-4">
              <table class="table table-hover table-nowrap mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product #</th>
                    <th>Product Name</th>
                    <th>Product Price</th>
                    <th>Order #</th>
                    <th>Total</th>
                    <th>Discount</th>
                    <th>Quantity</th>
                    <th>Order Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(orderItem, index) in contact.order_items" :key="index">
                    <td>#{{ index + 1 }}</td>
                    <td>{{ orderItem.product_id }}</td>
                    <td>{{ orderItem.product_name }}</td>
                    <td>{{ orderItem.product_price | currency }}</td>
                    <td>{{ orderItem.order_id }}</td>
                    <td>{{ orderItem.total | currency }}</td>
                    <td>{{ orderItem.discount | currency }}</td>
                    <td>{{ orderItem.quantity }}</td>
                    <td>{{ orderItem.ordered_at | datetime(`${$store.getters['auth/dateFormat']} HH:mm:ss`) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mt-0 mb-0 header-title">Redemptions</h5>
            <div class="table-responsive mt-4">
              <table class="table table-hover table-nowrap mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Keyword</th>
                    <th>Redeem code</th>
                    <th>Request date</th>
                    <th>Redeem date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(redemption, index) in contact.redemptions" :key="index">
                    <td>#{{ index + 1 }}</td>
                    <td>
                      <span v-if="!redemption.broadcast">
                        {{ redemption.keyword }}
                      </span>
                      <span v-else v-b-tooltip.hover title="This is a redemption from Broadcast">
                        {{ redemption.broadcast.name }}<span class="text-success font-weight-bold"> ( B )</span>
                      </span>
                      <span v-if="redemption.coupon_code" v-b-tooltip.hover
                        title="This is a Redemption from Static Coupon">
                        <span class="text-primary font-weight-bold"> ( S )</span>
                      </span>
                    </td>
                    <td>{{ redemption.coupon_code ? redemption.coupon_code : redemption.redemption_code }}</td>
                    <td>{{
                      redemption.created_at | datetime(`${$store.getters['auth/dateFormat']} HH:mm:ss`)
                    }}</td>
                    <td>{{
                      redemption.redeemd_at | datetime(`${$store.getters['auth/dateFormat']} HH:mm:ss`)
                    }}</td>
                    <td>{{ redemption.total_amount | currency }}</td>
                    <td>
                      <span v-if="redemption.status === 'redeemed'" class="badge badge-soft-success">Redeemed</span>
                      <span v-else-if="redemption.status === 'expired'" class="badge badge-soft-danger">Expired
                      </span>
                      <span v-else class="badge badge-soft-warning">Active</span>
                    </td>
                    <td>
                      <b-dropdown variant="black" toggle-class="arrow-none text-muted pr-0" right>
                        <template v-slot:button-content>
                          <i class="uil uil-ellipsis-v"></i>
                        </template>

                        <b-dropdown-item v-if="redemption.status !== 'redeemed'" @click="redeem(redemption)">
                          <i class="uil uil-atm-card mr-2"></i> Redeem
                        </b-dropdown-item>
                        <b-dropdown-item v-if="redemption.status === 'redeemed'" @click="edit(redemption)">
                          <i class="uil uil-edit mr-2"></i> Edit
                        </b-dropdown-item>
                        <b-dropdown-item @click="destroy(redemption)">
                          <i class="uil uil-trash mr-2"></i> Delete
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showRedeemModal" :title="`Redeem #${selectedRedemption ? selectedRedemption.redemption_code : ''
      }`
      ">
      <div v-if="selectedRedemption">
        <TextInput v-model="selectedRedemption.total_amount" name="amount" label="Total amount spent" type="number"
          rules="required" />
      </div>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showRedeemModal = false">Cancel</b-button>
        <b-button :disabled="!Number(selectedRedemption.total_amount) || loadingRedeem" variant="success"
          @click="handleRedeem">
          <b-spinner v-if="loadingRedeem" small />
          <span v-else>Redeem</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showEditModal" :title="`Edit #${selectedRedemption ? selectedRedemption.redemption_code : ''
      }`
      ">
      <div v-if="selectedRedemption">
        <TextInput v-model="selectedRedemption.total_amount" name="amount" label="Total amount spent" type="number"
          rules="required" />
      </div>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showEditModal = false">Cancel</b-button>
        <b-button :disabled="!Number(selectedRedemption.total_amount) || loadingEdit" variant="success"
          @click="handleSave">
          <b-spinner v-if="loadingEdit" small />
          <span v-else>Save</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showDeleteModal" title="Delete redemption">
      <p v-if="selectedRedemption">Are you sure you want to delete this redemption?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false">Cancel</b-button>
        <b-button variant="danger" @click="handleDestroy">Delete</b-button>
      </template>
    </b-modal>

    <b-modal v-model="isShowAutomationAddModal" title="Run Automation">
      <div class="form-group">
        <label>Automation</label>
        <multiselect v-model="selectedAutomation" :options="automations" :multiple="false" track-by="id"
          placeholder="Select automation" label="name"></multiselect>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" :disabled="isLoadingStartAutomation || !selectedAutomation"
          @click="handleStartAutomation">
          <b-spinner v-if="isLoadingStartAutomation" small />
          <span v-else>Start</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="isShowDeleteAutomationModal" title="Stop automation">
      <p v-if="selectedAutomation">Are you sure you want to stop this automation <b>"{{ selectedAutomation.name }}"</b>?
      </p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="isShowDeleteAutomationModal = false">Cancel</b-button>
        <b-button variant="danger" :disabled="isLoadingStopAutomation" @click="handleStopAutomation">
          <b-spinner v-if="isLoadingStopAutomation" small />
          <span v-else>Stop</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import CustomFields from '../../Common/CustomFields.vue'
export default {
  components: { Multiselect, CustomFields, },

  data() {
    return {
      loadingContact: false,
      contact: null,
      loadingUpdate: false,
      loadingPassword: false,
      password: {
        password: '',
        password_confirmation: '',
      },
      selectedRedemption: null,
      showRedeemModal: false,
      showEditModal: false,
      loadingRedeem: false,
      loadingEdit: false,
      keywords: [],
      showDeleteModal: false,
      automations: [],
      isShowAutomationAddModal: false,
      isLoadingStartAutomation: false,
      isLoadingStopAutomation: false,
      isShowDeleteAutomationModal: false,
      selectedAutomation: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    useIntegration() {
      return this.user && this.user.business && this.user.business.use_integration
    },

    showPointLinks() {
      return this.contact && this.contact.rewards && this.contact.rewards.length
    }
  },

  mounted() {
    this.getKeywords()
    this.getContact()
  },

  methods: {
    handleAddAutomation() {
      this.getAutomations()
      this.isShowAutomationAddModal = true
    },

    getAutomations() {
      this.$store.dispatch('automation/all')
        .then(automations => {
          this.automations = automations
        })
        .catch(() => {

        })
    },

    handleStopAutomation() {
      this.isLoadingStopAutomation = true

      this.$store.dispatch('contact/stopAutomation', {
        contactId: this.contact.id,
        automationId: this.selectedAutomation.id,
      })
        .then((automations) => {
          this.contact.automations = automations
          this.isShowDeleteAutomationModal = false
          this.isLoadingStopAutomation = false
          this.selectedAutomation = null
        })
        .catch(() => {
          this.isLoadingStopAutomation = false
        })
    },

    handleStartAutomation() {
      this.isLoadingStartAutomation = true

      this.$store.dispatch('contact/startAutomation', {
        contactId: this.contact.id,
        automationId: this.selectedAutomation.id,
      })
        .then((automations) => {
          this.contact.automations = automations
          this.isShowAutomationAddModal = false
          this.isLoadingStartAutomation = false
          this.selectedAutomation = null
        })
        .catch(() => {
          this.isLoadingStartAutomation = false
        })
    },

    onStopAutomation(automation) {
      this.isShowDeleteAutomationModal = true
      this.selectedAutomation = automation
    },

    getKeywords() {
      this.$store
        .dispatch('coupon/getKeywordsWithoutDemo')
        .then((keywords) => {
          this.keywords = keywords
        })
        .catch(() => {
        })
    },

    updateContact() {
      this.loadingUpdate = true
      let phone = this.contact.phone
      if (phone) {
        phone = parsePhoneNumberFromString(this.contact.phone)
        phone = phone ? phone.number : this.contact.phone
      }
      const requestParam = {
        id: this.contact.id,
        birthday: this.contact.birthday,
        anniversary: this.contact.anniversary,
        phone: phone,
        email: this.contact.email,
        first_name: this.contact.first_name,
        last_name: this.contact.last_name,
        business_name: this.contact.business_name,
        website_url: this.contact.website_url,
        toggle_opt_out: this.contact.toggle_opt_out,
        toggle_email_opt_out: this.contact.toggle_email_opt_out,
        keywords: this.contact.keywords,
        custom_fields: this.contact.custom_fields,
      }
      this.$store
        .dispatch('contact/update', requestParam)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    getContact() {
      this.loadingContact = true

      this.$store
        .dispatch('contact/find', this.$route.params.id)
        .then((contact) => {
          this.contact = contact
          this.contact.toggle_opt_out = !contact.opt_out
          this.contact.toggle_email_opt_out = !contact.email_opt_out
          this.loadingContact = false
        })
        .catch(() => {
          this.$router.push({ name: 'business.contacts.index' })
          this.loadingContact = false
        })
    },

    redeem(redemption) {
      this.selectedRedemption = JSON.parse(JSON.stringify(redemption))
      this.showRedeemModal = true
    },

    handleRedeem() {
      this.loadingRedeem = true

      this.$store
        .dispatch('coupon/redeem', this.selectedRedemption)
        .then(() => {
          this.showRedeemModal = false
          this.loadingRedeem = false
          this.getContact()
        })
        .catch(() => {
          this.loadingRedeem = false
        })
    },

    edit(redemption) {
      this.selectedRedemption = JSON.parse(JSON.stringify(redemption))
      this.showEditModal = true
    },

    handleSave() {
      this.loadingEdit = true

      this.$store
        .dispatch('contact/updateRedemption', this.selectedRedemption)
        .then((redemption) => {
          this.showEditModal = false
          this.loadingEdit = false

          this.contact.redemptions = this.contact.redemptions.map(r => r.id !== redemption.id ? r : redemption)
          this.getContact()
        })
        .catch(() => {
          this.loadingRedeem = false
        })
    },

    destroy(redemption) {
      this.selectedRedemption = redemption
      this.showDeleteModal = true
    },

    handleDestroy() {
      this.showDeleteModal = false

      this.$store
        .dispatch('contact/removeRedemption', this.selectedRedemption.id)
        .then(() => {
          this.contact.redemptions = this.contact.redemptions.filter(r => r.id !== this.selectedRedemption.id)
          this.getContact()
        })
        .catch(() => { })
    },

    getAutomationTooltip(item) {
      let result = '';

      if (item.processes.length) {
        let endAt = null;
        let startAt = null;

        item.processes.forEach(process => {
          startAt = process.start_at ? process.start_at : null
          endAt = process.end_at ? process.end_at : null
        })

        if (!startAt)
          startAt = item.created_at

        if (startAt) {
          result += `Started: ${this.$options.filters.shorttime(startAt)} - `
        }

        if (endAt) {
          result += `Completed: ${this.$options.filters.shorttime(endAt)}`
        }

        // let completed = item.processes.filter(process => process.end_at).length
        // let percent = parseInt(completed / item.processes.length * 100);
        // result += ` - ${percent}%`
      } else {
        result += `Started: ${this.$options.filters.shorttime(item.created_at)}`
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.url-ul {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style: none;
}
</style>